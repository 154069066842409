import { FC, useEffect, useRef } from "react"

import { CheckCircleTwoTone } from "@ant-design/icons"
import { OidcSecure } from "@axa-fr/react-oidc"
import { Layout,notification, Button, Typography } from "antd"
import { Outlet } from "react-router-dom"

import { Header } from "@/components/_Shared/Layout/Header"
import { Sidebar } from "@/components/_Shared/Layout/Sidebar"
import { useNotificationContext } from "@/context/NotificationContext"
import { useSignalR } from "@/context/SignalRContext"
import { WithSuspense } from "@/hoc/WithSuspense"

import classes from "./MainLayout.module.scss"

import { useNotificationMutation } from "@/hook/Notifications/useNotificationMutation"

import { INotification } from "@/types/INotificaiton"

const { Text } = Typography


export const MainLayout: FC = () => {
  const [ notificationApi, notificationContextHolder ] = notification.useNotification()
  const connection = useSignalR()
  const notificationAudio = useRef<HTMLAudioElement>(null)
  
  const { updateReadStatusForNotificationsMutation } = useNotificationMutation()
  const { refetchNotifications } = useNotificationContext()
  
  useEffect( () => {    
    showNotReadNotificatons()
    
    connection?.on("ReciveNotification", (notificaiton: INotification) => {
      notificationAudio.current!.muted = false
      notificationAudio!.current!.currentTime = 0
      notificationAudio.current?.play().catch(error=>{
        console.log("Воспроизведение заблокировано:", error)
      })
      notificationApi.info({
        message : "Уведомление",
        description : <Text>{notificaiton.message}</Text>,
        btn: <>
          <Button type="link" onClick={()=>readAndCloseNotification(notificaiton.id)} >
            <CheckCircleTwoTone /> Прочитано
          </Button></>,
      placement: "top",
      duration: 20,
      key: notificaiton.id
      })
    })
  }, [connection, notificationApi])

  const showNotReadNotificatons = async () =>{
    const notifications = await connection?.invoke<INotification[]>("GetNotReadNotification") ?? []

    for(const notificaiton of notifications){
      notificationApi.info({
        message : "Уведомление",
        description : <Text>{notificaiton.message}</Text>,
        btn: <>
          <Button type="link" onClick={()=>readAndCloseNotification(notificaiton.id)} >
            <CheckCircleTwoTone /> Прочитано
          </Button></>,
        placement: "top",
        duration: 20,
        key: notificaiton.id
      })
    }
  }

  const readAndCloseNotification = async (idNotification:number) => {
    await updateReadStatusForNotificationsMutation.mutateAsync({
      ids: [ idNotification ],
      isRead: true
    })

    notificationApi.destroy(idNotification)
    refetchNotifications()
  }

  return (
  <Layout className={classes.layout}>
    <Sidebar />
    <Layout>
      <Header />
      <Layout.Content className={classes.content}>
        <div className={classes["page-wrapper"]}>
          {notificationContextHolder}
          <audio ref={notificationAudio} src="notification-sound.mp3" muted autoPlay/>
          <WithSuspense>
            <OidcSecure>
              <Outlet />
            </OidcSecure>
          </WithSuspense>
        </div>
      </Layout.Content>
    </Layout>
  </Layout>)

  
}