import React, {
  ChangeEvent,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react"


import {
  ArrowsAltOutlined,
  DashboardTwoTone,
  EyeInvisibleOutlined,
  IdcardOutlined,
  PlusOutlined,
  ProfileOutlined,
  UnderlineOutlined
} from "@ant-design/icons"
import {
  Spin,
  Tabs,
  Row,
  Col,
  Typography,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  TimePicker,
  message,
  Flex,
  Collapse,
  Modal,
  Divider,
  Alert,
  Space, TimeRangePickerProps as AntDatePickerProps, TabsProps,
  Checkbox,
  Radio,
  notification,
  Segmented,
  InputNumber
} from "antd"
import { RadioChangeEvent } from "antd/lib"
import dayjs, { Dayjs, OptionType } from "dayjs"
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker"

import { CustomRangePicker } from "@/components/_Shared/CustomRangePicker"
import { DatePicker } from "@/components/_Shared/DatePicker"
import { SimpleModal, SimpleModalRef } from "@/components/_Shared/SimpleModal"
import { TabForm } from "@/components/_Shared/TabForm"
import { DeliveryAddressesAddForm, DeliveryAddressesAddFormProps } from "@/components/DeliveryAddresses/DeliveryAddressesAddForm/DeliveryAddressesAddForm"
import { TransportTable } from "@/components/Transport/TransportTable"
import { Resource, Scope } from "@/constants/permission"
import { CustomerContext } from "@/context/CustomerContext"
import { getCustomersSelectList } from "@/http/customer"
import { getFuelForOrder, getFuelsForOrderByDepot } from "@/http/fuel"
import { getFuelLimitById } from "@/http/fuelLimit"
import { getFuelTypeByDepot } from "@/http/fuelType"
import { getAvailableTimeUnloadFuel,getFreeSlotForUnload } from "@/http/timeUnloadFuel"

import classes from "./OrderAddForm.module.scss"
import { BackdatingOrderAddForm } from "../BackdatingOrderAddForm/BackdatingOrderAddForm"
import { DeliveryForm, DeliveryFormRef } from "../DeliveryForm"
import { OrderDeliveryTable } from "../OrderDeliveryTable/OrderDeliveryTable"
import { OrderFuelTable } from "../OrderFuelTable"


import { useCustomerByIdQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"
import { useDeliveryAddressForOrderQuery } from "@/hook/Dictionaries/useDeliveryAddressQuery"
import { useDepotSelectListQuery } from "@/hook/Dictionaries/useDepotQuery"
import { useDriverForOrderQuery } from "@/hook/Dictionaries/useDriverQuery"
import { useTransportForOrderQuery } from "@/hook/Dictionaries/useTransportQuery"
import { useFuelLimitsMutation } from "@/hook/FuelLimits/useFuelLimitsMutation"
import { useFuelQuotesMutation } from "@/hook/FuelQuotes/useFuelQuotesMutation"
import { useOrderMutation } from "@/hook/Orders/useOrderMutation"
import { useOrderByIdQuery } from "@/hook/Orders/useOrderQuery"
import { useTimeUnloadFuelMutation } from "@/hook/TimeUnloadFuel/useTimeUnloadFuelMutation"
import { usePermission } from "@/hook/usePermission"

import { Customer, ICustomer, ICustomerSelectListItem } from "@/types/ICustomer"
import { IDelivery } from "@/types/IDelivery"
import { IDeliveryAddress } from "@/types/IDeliveryAddress"
import { IDepotFuel } from "@/types/IDepot"
import { IDriver } from "@/types/IDriver"
import { getTransformedFuelDensity, IFuelOrder, convertFuelAmountToLitres, convertFuelAmountToTonn } from "@/types/IFuel"
import { IFuelLimit, IFuelLimitCreate } from "@/types/IFuelLimit"
import { IFuelQuoteCreate } from "@/types/IFuelQuote"
import { IFuelType } from "@/types/IFuelType"
import {
  DeliveryType,
  ExchangeType,
  IOrderFuel,
  IOrderCreate,
  ITranportPairOrder,
  deliveryTypes,
  exchangeTypes,
  OrderStatus
} from "@/types/IOrder"
import { ITimeUnloadFuel } from "@/types/ITimeUnloadFuel"
import { ITransport, ITransportClass } from "@/types/ITransport"

import { filterSelectOption } from "@/utils/filterSelectOption"
import {
  deserializeRangeDayjs,
  formatDate,
  serializeRangeDayjs
} from "@/utils/formatDate"
import { formatFuelVolume, getMoney } from "@/utils/formatNumber"
import { formatTransport } from "@/utils/formatString"



const { Text, Title } = Typography
const { TextArea } = Input


export interface OrderAddFormProps {
  open(
    customer?: ICustomer,
    id?: number,
    isReserve?: boolean,
    depotId?: number,
    editByCustomer?: boolean,
    isDeliveryOrder?: boolean
  ): void
  close(): void
}

export interface OrderAddFormValues {
  date: Date
  customerId: number
  deliveryType: DeliveryType
  exchangeType?: ExchangeType
  wishPeriodTime?: string
  isReserve: boolean
  transports: TransportAddFormValues[]
  fuelQuotes: FuelQuoteAddFormValues[]
  deliveryAddressId?: number
  deliveryCost?: number
  pump?: boolean
  deliveryCommentary?: string
  depotId: number
  fuelTypeId: number
  amount: number
  price: number
  isFuture: boolean
  withPayment: boolean,
  isCustomDeliveryCost: boolean,
  customDeliveryCost: number
}

export interface FuelQuoteAddFormValues {
  id: number
  quoteId: number
  amount: number
  fuelType: string
  density: number
  price: number
  addon: number,
  isFuture: boolean,
  limitId: number
}

export interface TransportAddFormValues {
  driverId: number
  truckId: number
  pickupId?: number
  wishTimeSlot?: string
  fuelAmount: number
}

export interface OrderData {
  isReserve: boolean
  date: string
  customer: CustomerData
  wishPeriodTime?: string
  deliveryType: string
  delivery?: IDelivery
  exchangeType?: string
  driver?: IDriver
  truck?: ITransport
  pickup?: ITransport
  depot: string
  depotId: number
  fuels: IOrderFuel[]
  transports: ITranportPairOrder[]
  deliveryPrice: number
  fullPrice: number
  finalPriceText: string
  isFuture: boolean
}

export interface CustomerData {
  id: number
  name: string
}

enum InputValueOrWeight{
  volume,
  weight
}

export const OrderAddForm = forwardRef<OrderAddFormProps>((props, ref) => {
  
  const customerContext = useContext(CustomerContext)
  const [form] = Form.useForm<OrderAddFormValues>()
  const [id, setId] = useState<number>(0)
  const [totalTransportLiter, setTotalTransportLiter ] = useState<number>(0)
  const [isEditByCustomer, setIsEditByCustomer] = useState<boolean>(false)
  const [hasWishTime, setHasWishTime] = useState<boolean>(false) 
  const [isCustomerOrder, setIsCustomerOrder] = useState<boolean>(true)
  const [customers, setCustomers] = useState<ICustomerSelectListItem[]>([])
  const [customer, setCustomer] = useState<ICustomer>()
  const [isReserve, setIsReserve] = useState<boolean>(false)
  const [isDelivery, setIsDelivery] = useState<boolean>(false)
  const [isPlanned, setIsPlanned] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState(false)
  const [activeKey, setActiveKey] = useState("1")
  const [isProcessing, setIsProcessing] = useState(false)
  const [isDriverChosen, setIsDriverChosen] = useState(false)
  const [isTruckChosen, setIsTruckChosen] = useState(false)
  const [isTimeUnloadChosen, setIsTimeUnloadChosen] = useState(false)
  const [radioFeulMeasurement, setRadioFeulMeasurement ] = useState<string>("litr")
  const [orderDate, setOrderDate] = useState<Date | undefined>(undefined)
  const [ deliveryAddres, setDeliveryAddres ] = useState<IDeliveryAddress | undefined>(undefined)
 
  const [fuels, setFuels] = useState<IDepotFuel[]>()
  const [fuelTypes, setFuelTypes] = useState<IFuelType[]>()
  const [timeUnloadFuels, setTimeUnloadFuels] = useState<ITimeUnloadFuel[]>([])
  const [chosenTimeUnloadFuel, setChosenTimeUnloadFuel] = useState<ITimeUnloadFuel | undefined>(undefined)
  const [isFuelTypesLoaded, setIsFuelTypesLoaded] = useState(false)
  const [fuel, setFuel] = useState<IFuelOrder>()
  const [selectFuelLimit, setSelectFuelLimit ] = useState<IFuelLimit>()
  const [isFuelLoaded, setIsFuelLoaded] = useState<boolean>(false)
  const [amountFuel, setAmountFuel] = useState<number>(0)
  const [isChosenTransportLiterOk, setIsChosenTransportLiterOk] = useState<boolean>(false)
  const [transportLiterErrorMessage, setTransportLiterErrorMessage] = useState<string | null>(null)
  const [cannotAddFuel, setCannotAddFuel] = useState(true)
  const [chosenTransports, setChosenTransports] = useState<
    ITranportPairOrder[]
  >([])
  const [isTransportSelect, setIsTransportSelect] = useState<boolean>(false)
  const [chosenDepot, setChosenDepot] = useState<string>()
  const [isFuelSelect, setIsFuelSelect] = useState<boolean>(false)
  const [chosenFuels, setChosenFuels] = useState<IOrderFuel[]>([])
  const [price, setPrice] = useState(0)
  const [fullPrice, setFullPrice] = useState(0)
  const [isOrderOnClientApprovingTransportStatus, setIsOrderOnClientApprovingTransportStatus] = useState<boolean>(false)

  const [isFuelsLoaded, setIsFuelsLoaded] = useState<boolean>(false)
  const [isOverLimitApproved, setIsOverLimitApproved] = useState<boolean>(false)
  const [chosenDeliveryType, setChosenDeliveryType] = useState<DeliveryType>(DeliveryType.Pickup)
  const [totalAmount, setTotalAmount] = useState<number>(0)

  const [newDrivers, setNewDrivers] = useState<IDriver[]>([])
  const [newTransports, setNewTransports] = useState<ITransport[]>([])
  const [isAddedNewDriver, setIsAddedNewDriver] = useState<boolean>(false)
  const [isAddedNewTransport, setIsAddedNewTransport] = useState<boolean>(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalText, setModalText] = useState("")
  const [isAddFuelProcess, setIsAddFuelProcess] = useState<boolean>(false)
  
  const { hasPermission } = usePermission()

  const [data, setData] = useState<OrderAddFormValues>()
  const [orderData, setOrderData] = useState<OrderData>()

  const { data: customerData, isLoading: customerIsLoading } = useCustomerByIdQuery(customerContext?.customer?.id ?? 0)
  const { data: depots, isLoading: depotsIsLoading } = useDepotSelectListQuery()
  const { data: drivers, isLoading: driversIsLoading } =
    useDriverForOrderQuery(id, customer?.id)
  const { data: transports, isLoading: transportsIsLoading } =
    useTransportForOrderQuery(id, customer?.id)
  const { data: deliveryAddresses, isLoading: deliveryAddressesLoading } = 
    useDeliveryAddressForOrderQuery(customer?.id ?? 0)
  const { data: order } = useOrderByIdQuery(id)
  

  const [messageApi, contextHolder] = message.useMessage()
  const simpleModalComponent = useRef<SimpleModalRef>(null)
  const addressComponent = useRef<DeliveryAddressesAddFormProps>(null)
  const deliveryForm = useRef<DeliveryFormRef>(null)


  const { createMutation, updateMutation, updateByCustomerMutation, updateTransportForOrderMutation, recreateMutation } = useOrderMutation()
  const { getAvailableTimeUnloadFuelsMutation,createTimeUnloadFuelsMutation } = useTimeUnloadFuelMutation()
  const { createMutation: createFuelQuoteMutation } = useFuelQuotesMutation()
  const { createMutation: createLimitFuelMutation } = useFuelLimitsMutation()

  const [ notificationApi, notificationContextHolder ] = notification.useNotification()

  useImperativeHandle(ref, () => ({
    open(
      customer?: ICustomer,
      id?: number,
      isReserve?: boolean,
      depotId?: number,
      isEditByCustomer?: boolean,
      isDeliveryOrder?: boolean
    ) {
      onOpenHandler(customer, id, isReserve, depotId, isEditByCustomer,isDeliveryOrder)
    },
    close() {
      onCloseHandler()
    }
  }))

  useEffect(() => {
    if (order && isOpen) {
      const response = order.response

      setChosenDepot(response.depot.name)

      form.setFields([
        { name: "date", value: dayjs(response.date) },
        { name: "deliveryType", value: response.deliveryType },
        { name: "exchangeType", value: response.exchangeType },
        { name: "customerId", value: response.customer.id },
        { name: "deliveryCost", value: response.deliveryPrice },
        { name: "deliveryAddressId", value: response?.delivery?.deliveryAddress?.id },
        { name: "wishTimeSlot", value: response.wishTimeSlot },
        { name: "depotId", value: response.depot.id },
        { name: "isReserve", value: false },
        { name: "transports", value: response.transports },
        { name: "fuelQuotes", value: response.fuelQuotes },
        { name: "deliveryCommentary", value: response.delivery?.commentary ?? "-" },
        { name: "pump", value: response.delivery?.pump ?? false }
      ])

      if (isEditByCustomer) {
        uploadFuelTypes(response.depot.id)
        if (!isDelivery && hasWishTime) {
          timeUnloadFuelLoad()
        }
      }

      if(response.status == OrderStatus.OnClientApprovingTransport){
        setIsOrderOnClientApprovingTransportStatus(true)
      }

      if (response.deliveryType == DeliveryType.Delivery ) {
        setIsDelivery(true)
        form.setFieldValue("deliveryCommentary", response.delivery?.commentary ?? "-")
        form.setFieldValue("pump", response.delivery?.pump ?? false)
      }
      else if( response.status == OrderStatus.NotSettedTime){
        setIsEditByCustomer(false)
      }

      if (order.response.fuelQuotes && order.response.fuelQuotes.length > 0) {
        setIsFuelSelect(true)
      }
      
      if (order.response.transports && order.response.transports.length > 0) {
        setIsTransportSelect(true)
      }

      refershChosenFuels(response.fuelQuotes)
      refreshChosenTransports(response.transports ?? [])
      setChosenDeliveryType(response.deliveryType)
      setHasWishTime(response.depot.hasWishTime)

      var totalTransportLiter = response.transports.reduce((sum, transport) => sum + (Number(transport.truck?.liter) ?? 0), 0)
      setTotalTransportLiter(totalTransportLiter)
    }
  }, [id, order, form, isOpen, drivers, transports])

  const title = useMemo(() => {
    if (id) {
      return "Редактировать"
    }
    if (isPlanned) {
      return "Забронировать"
    }
    if (isReserve) {
      return "Забронировать"
    }

    return "Заказать"
  }, [id, isReserve, isPlanned])

  const formOrderText = useMemo(() => {
    return id == 0 ? "Сформировать заказ" : "Изменить заказ"
  }, [id])

  const orderText = useMemo(() => {
    if (id) return "Подтвердить изменения"

    return "Создать заказ"
    // return !isCustomerOrder &&
    //   orderData &&
    //   customer &&
    //   customer.availableSum >=
    //   (orderData.isReserve ? orderData.fullPrice * 0.15 : orderData.fullPrice)
    //   ? "Создать и оплатить заказ"
    //   : 
    //   "Создать заказ"
  }, [id])

  const allDrivers: IDriver[] = useMemo(() => {
    return [...(drivers?.response ?? []), ...(newDrivers ?? [])]
  }, [drivers, isAddedNewDriver])


  const allTrucks: ITransport[] = useMemo(() => {
    return [
      ...(transports?.response.filter(
        (t) => t.class == ITransportClass.Truck
      ) ?? []),
      ...(newTransports.filter((t) => t.class == ITransportClass.Truck) ?? [])
    ]
  }, [transports, isAddedNewTransport])

  const allPickups: ITransport[] = useMemo(() => {
    return [
      ...(transports?.response.filter(
        (t) => t.class == ITransportClass.Pickup
      ) ?? []),
      ...(newTransports.filter((t) => t.class == ITransportClass.Pickup) ?? [])
    ]
  }, [transports, isAddedNewTransport])

  const onOpenHandler = (
    customer?: ICustomer,
    id?: number,
    isReserve?: boolean,
    depotId?: number,
    isEditByCustomer?: boolean,
    isDeliveryOrder?: boolean
  ) => {
    if (id) {
      setId(id)
    }

    form.resetFields()

    if (customer) {
      setCustomers([])
      form.setFieldValue("customerId", customer.id)
      setCustomer(customer)
      setIsCustomerOrder(true)
    } else {
      getCustomersSelectList().then((response) => {
        setCustomers(response?.response ?? [])
        setIsCustomerOrder(false)
      })
    }

    if (isReserve) {
      setIsReserve(isReserve)
    }
    if (isDeliveryOrder) {
      setIsDelivery(isDeliveryOrder)
    }

    if (isEditByCustomer) {
      setIsEditByCustomer(isEditByCustomer)
    }

    if (depotId) {
      form.setFieldValue("depotId", depotId)
      onDepotChange(undefined, undefined)
    }
    setIsFuelsLoaded(false)
    setIsOpen(true)
  }

  const onCustomerChange = (customerId: number) => {
    setCustomer(new Customer(customers.find(c => c.value == customerId)))

    refreshChosenTransports([])
    form.setFieldValue("transports", [])
    form.setFieldValue("driverId", undefined)
    form.setFieldValue("truckId", undefined)
    form.setFieldValue("pickupId", undefined)
    setIsDriverChosen(false)
    setIsTruckChosen(false)
    setIsTimeUnloadChosen(false)
  }

  const tryOrder = async (data: OrderAddFormValues | undefined) => {
    try {
      if (data == undefined) return

      const request: IOrderCreate = {
        //Преобразуем дату из формы, чтобы игнорировать таймзону
        date: dayjs(data.date).format("YYYY-MM-DD"),
        deliveryType: data.deliveryType,
        delivery: {
          deliveryAddressId: data.deliveryAddressId ?? 0,
          pump: data.pump,
          commentary: data.deliveryCommentary
        },
        depotId: data.depotId,
        exchangeType: data.exchangeType,
        customerId: data.customerId,
        deliveryPrice: data?.deliveryCost ?? 0,
        wishTimeSlot: data.wishPeriodTime,
        isReserve: data.isReserve,
        transports: data.transports.map((t: TransportAddFormValues) => ({
          driverId: t.driverId,
          truckId: t.truckId,
          pickupId: t.pickupId,
          wishTimeSlot: t.wishTimeSlot,
          fuelAmount: t.fuelAmount
        })),
        fuelQuoteOrders: data.isFuture
          ? []
          : data.fuelQuotes.map((fq: FuelQuoteAddFormValues) => ({
            id: fq.quoteId,
            amount: Number(fq.amount),
            limitId: fq.limitId
          })),
        fuelFutureOrders: data.isFuture
          ? data.fuelQuotes.map((fq: FuelQuoteAddFormValues) => ({
            id: fq.id,
            amount: Number(fq.amount),
            avgPrice: fq.price,
            addon: fq.addon,
            limitId: fq.limitId == 0 ? null : fq.limitId
          }))
          : [],
        newAddresses: [],
        newDrivers: newDrivers,
        newTransports: newTransports
      }

      setIsProcessing(true)
      
      if (id) {
        if (order?.response && order.response.status == OrderStatus.NotSettedTime) {
          await recreateMutation.mutateAsync({
            ...request,
            id
          })
        }
        else{
          if(isOrderOnClientApprovingTransportStatus && isEditByCustomer){
            await updateTransportForOrderMutation.mutateAsync({
              ...request,
              id
            })
          }
          else if (isEditByCustomer) {
            await updateByCustomerMutation.mutateAsync({
              ...request,
              id
            })
          }
          else {
            await recreateMutation.mutateAsync({
              ...request,
              id
            })
          }
        }
      } else {
        await createMutation.mutateAsync(request)
      }

      onCloseHandler()
    } catch {
    } finally {
      setIsProcessing(false)
    }
  }

  const handleOk = async () => {
    setIsModalOpen(false)
    await tryOrder(data)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }


  const onFinish = async (data: OrderAddFormValues) => {
    const fuelQuotes: FuelQuoteAddFormValues[] = form.getFieldValue("fuelQuotes") ?? []
    const orderTransports: TransportAddFormValues[] = form.getFieldValue("transports") ?? []
    const isFuture: boolean = fuelQuotes.some((fq) => fq.isFuture == true)

    const wishTimeSlot = form.getFieldValue("wishTimeSlot")
      if (wishTimeSlot) {
       data.wishPeriodTime = wishTimeSlot
      }
      else {
        data.wishPeriodTime = timeUnloadFuels.find(t=>t.id == form.getFieldValue("wishTimeSlotId"))?.slot
      }
    data.deliveryType = isDelivery ? DeliveryType.Delivery : DeliveryType.Pickup  
    data.fuelQuotes = fuelQuotes
    data.transports = orderTransports
    data.isFuture = isFuture
    data.isReserve = isReserve
    setData(data)

    // if (isFuture) {
    //   setModalTitle("Предупреждение")
    //   setModalText(`По одной или нескольки позициям ещё нет данных, поэтому заказ будет заморожен до тех пор,
    //     пока данные по всем позициям не появятся. Всё равно продолжить заказ?`)
    //   setIsModalOpen(true)
    // } else if (
    //   orderTransports.length == 0 &&
    //   chosenDeliveryType != DeliveryType.Delivery
    // ) {
    //   setModalTitle("Предупреждение")
    //   setModalText(`Без указания транспортного средства (ТС) можно только зарезервировать позиции стоимость 15% от общей суммы.
    //     После формирования заказа будет время внести информацию о ТС, чтобы подтвердить заказ. Всё равно продолжить заказ?`)
    //   setIsModalOpen(true)
    // } else {
      await tryOrder(data)
    //}
  }

  const onCloseHandler = () => {
    form.resetFields()
    setActiveKey("1")
    setId(0)
    setCustomer(undefined)
    setPrice(0)
    setFullPrice(0)
    setFuel(undefined)
    setHasWishTime(false)
    setChosenTransports([])
    setChosenFuels([])
    setChosenDepot(undefined)
    setIsDriverChosen(false)
    setIsTruckChosen(false)
    setIsTimeUnloadChosen(false)
    setIsReserve(false)
    setIsEditByCustomer(false)
    setIsPlanned(false)
    setIsOpen(false)
    setTotalTransportLiter(0)
    setHasWishTime(false)
    setIsChosenTransportLiterOk(false)
    setIsFuelSelect(false)
    setIsTransportSelect(false)
    setTimeUnloadFuels([])
    setIsAddFuelProcess(false)
    setOrderDate(undefined)
    setIsFuelLoaded(false)
    setIsDelivery(false)
  }

  const onDateChange = (e: any, dateString: string | string[]) => {
    setOrderDate(new Date(dayjs(form.getFieldValue("date")).format("YYYY-MM-DD")))
    if (hasWishTime && !isDelivery) {
      timeUnloadFuelLoad()
    }
    fuelLoad() 
  }

  const timeUnloadChange = () =>{
    const timeUnloadId = form.getFieldValue("wishTimeSlotId")
    if (timeUnloadId) {
      setIsTimeUnloadChosen(true)
      setChosenTimeUnloadFuel(timeUnloadFuels.find(t=>t.id == timeUnloadId))
    }
  }

  const onAddTransportInOrder = async ()  => {
    const driverId = form.getFieldValue("driverId")
    const truckId = form.getFieldValue("truckId")
    const pickupId = form.getFieldValue("pickupId")
    const timeUnloadId = form.getFieldValue("wishTimeSlotId")
    const fuelAmoutTransport = form.getFieldValue("fuelAmoutTransport")

    const orderTransports: TransportAddFormValues[] = form.getFieldValue("transports") ?? []

    if (
      orderTransports.some(
        (ot) =>
          ot.driverId == driverId ||
          ot.truckId == truckId ||
          (pickupId != undefined && ot.pickupId == pickupId)
      )
    ) {
      return
    }

    orderTransports.push({
      driverId: driverId,
      truckId: truckId,
      pickupId: pickupId,
      wishTimeSlot: timeUnloadFuels.find(tuf=>tuf.id == timeUnloadId)?.slot,
      fuelAmount: fuelAmoutTransport
    })

    if (hasWishTime) {
      const totalAmountFuel = chosenFuels.reduce((sum, quote) => sum + (convertFuelAmountToLitres(Number(quote.amount ?? 0),quote.density)), 0)
      const totalTransportLiterAmount = totalTransportLiter + (allTrucks.find(t=>t.id == truckId)?.liter ?? 0)
      setTotalTransportLiter(totalTransportLiterAmount)

      checkFuelAmountAndTransportLiter(totalAmountFuel, totalTransportLiterAmount)
    }

    setIsTransportSelect(true)
    refreshChosenTransports(orderTransports)
    form.setFieldValue("transports", orderTransports)
    form.setFieldValue("wishTimeSlot", timeUnloadFuels.find(f=>f.id == timeUnloadId)?.slot)
    form.setFieldValue("wishTimeSlotId",undefined)
    form.setFieldValue("driverId", undefined)
    form.setFieldValue("truckId", undefined)
    form.setFieldValue("pickupId", undefined)
    form.setFieldValue("fuelAmoutTransport", undefined)
    
    setIsDriverChosen(false)
    setIsTruckChosen(false)
  }


  const handleOpenAddDeliveryAddresFrom = () => {
    addressComponent.current?.open()
  }

  const onDeleteTransport = (transport: ITranportPairOrder) => {
    let orderTransports: TransportAddFormValues[] =
      form.getFieldValue("transports") ?? []
    orderTransports = orderTransports.filter((ot) => ot.driverId != transport.id)

    refreshChosenTransports(orderTransports)
    form.setFieldValue("transports", orderTransports)
    form.setFieldValue("wishTimeSlot", undefined)
    form.setFieldValue("wishTimeSlotId",undefined)
    setIsTransportSelect(false)
    setIsTimeUnloadChosen(false)

    if (hasWishTime) {
      const totalAmountFuel = chosenFuels.reduce((sum, quote) => sum + (convertFuelAmountToLitres(Number(quote.amount ?? 0),quote.density)), 0)
      const totalTransportLiterAmount = totalTransportLiter - (allTrucks.find(t=>t.id == transport.truckId)?.liter ?? 0)
      setTotalTransportLiter(totalTransportLiterAmount)
    
      checkFuelAmountAndTransportLiter(totalAmountFuel, totalTransportLiterAmount)
    }

  }

  const onDepotChange = (value: any, options: any) => {
    if (depots) {
      const depot = depots.response.find(
        (d) => d.id == form.getFieldValue("depotId")
      )

      if (depot) {
        uploadFuelTypes(depot.id)
        setHasWishTime(depot.hasWishTime)
      }

      if (depot?.hasWishTime) {
        setHasWishTime(true)
      }
      else {
        setHasWishTime(false)
      }
    }
  }

  const uploadFuelTypes = (depotId: number) => {
    if (depotId) {
      getFuelTypeByDepot(depotId).then(res =>{
        setFuelTypes(res.response ?? null)
        setIsFuelTypesLoaded(true)
      })
    }
  }

  const uploadFuels = () => {
    const depotId = form.getFieldValue("depotId")
    const date = form.getFieldValue("date")
    const fuelTypeId = form.getFieldValue("fuelTypeId")

    if (depotId && date && depots?.response) {
      getFuelsForOrderByDepot({
        DepotId: depotId,
        Date: new Date(dayjs(date).format("YYYY-MM-DD")),
        CustomerId: customer?.id ?? form.getFieldValue("customerId")
      }).then((res) => {
        setFuels(res.response)
        setCannotAddFuel(true)

        if (res.response.some((f) => f.isFuture)) {
          showPlannedModal()
        } else {
          setIsPlanned(false)
        }

        if (!isEditByCustomer) {
          form.setFieldValue("fuelQuotes", [])
          form.setFieldValue("fuelTypeId", undefined)
          form.setFieldValue("amount", undefined)
          form.setFieldValue("price", undefined)
          setFuel(undefined)

          refershChosenFuels([])
          setPrice(0) 
          setFullPrice(0)
        }

        setIsFuelsLoaded(true)
      })
    }
  }

  const showPlannedModal = () => {
    if (isPlanned) return

    simpleModalComponent.current?.open({
      title: "Предупреждение",
      text: "На данную дату лимиты не установлены. Перейти в бронирование?",
      ok: {
        text: "Да",
        action: () => {
          setIsPlanned(true)
          simpleModalComponent.current?.close()
        }
      },
      cancel: {
        text: "Нет",
        action: () => {
          setIsPlanned(false)
          form.setFieldValue("date", undefined)
          setFuels([])
          simpleModalComponent.current?.close()
        }
      }
    })
  }

  const calculatePrice = (amount: number) => {
    if (amount && amount > 0) {
      if (fuel?.price && fuel.price > 0) {
        const newPrice = amount * fuel.price
        setPrice(newPrice)
      }
      else if (fuel?.lastSetPrice){
        const newPrice = amount * fuel.lastSetPrice
        setPrice(newPrice)
      }
    }
    else{
      setPrice(0)
      setAmountFuel(amount ?? 0)
    }
  }

  const timeUnloadFuelLoad = async () => {
    const dateForm = form.getFieldValue("date")
    if (dateForm) {
      setTimeUnloadFuels([])
      await getAvailableTimeUnloadFuelsMutation.mutateAsync({
        date : new Date(dayjs(dateForm).format("YYYY-MM-DD"))
      }).then(res => {
        if (res.succeeded) {
          setTimeUnloadFuels(res.response)
        }
      })
    }
  }

  const fuelLoad = () => {
    const depotId = form.getFieldValue("depotId")
    const date = form.getFieldValue("date")
    const fuelTypeId = form.getFieldValue("fuelTypeId")
    
    if (depotId && date && fuelTypeId) {
      getFuelForOrder({
        Date: new Date(dayjs(date).format("YYYY-MM-DD")),
        FuelTypeId : fuelTypeId,
        DepotId : depotId,
        CustomerId: form.getFieldValue("customerId")
      }).then(res => {
        setFuel(res.response)
        setIsFuelLoaded(true)
      })
    }
  }
  
  const fuelTypeChange = () => {
    const fuelTypeId = form.getFieldValue("fuelTypeId")

    const fuelQuotes: FuelQuoteAddFormValues[] = form.getFieldValue("fuelQuotes") ?? []
    const fuelInOrder = fuelQuotes.find((fq) => fq.fuelType == fuelTypes?.find(ft=>ft.id == fuelTypeId)?.name)

    if (fuelInOrder) {
      setCannotAddFuel(true)
      form.setFieldValue("density", undefined)
      form.setFieldValue("price", undefined)
      setIsFuelLoaded(false)
      setIsAddFuelProcess(true)
      simpleModalComponent.current?.open({
        title: "Предупреждение",
        text: "Выбранный тип топлива уже добавлен. Обновить данные по топливу?",
        ok: {
          text: "Обновить",
          action: () => {
            fuelLoad()
            simpleModalComponent.current?.close()
          }
        },
        cancel: {
          text: "Выбрать другой тип топлива",
          action: () => {
            simpleModalComponent.current?.close()
          }
        }
      })
    }
    else{
      fuelLoad()
    }
  }
  
  const onWeightOrVolumeChange = (valueTypeChane: InputValueOrWeight) => {
    var amountLitres:number  = 0
    var amountTonn:number = 0

    switch (valueTypeChane) {
      case InputValueOrWeight.volume:
        amountLitres = form.getFieldValue("volume")
        if (amountLitres && !Number.isInteger(amountLitres)) {
          amountLitres = Math.floor(Number(amountLitres))
          form.setFieldValue("volume",amountLitres)
        }

        amountTonn = convertFuelAmountToTonn(amountLitres, fuel?.density ?? 1)
          form.setFieldValue("weight",amountTonn.toFixed(3))
          setAmountFuel(amountTonn) 
        break

      case InputValueOrWeight.weight:
        amountTonn = form.getFieldValue("weight")
        amountLitres = convertFuelAmountToLitres(amountTonn, fuel?.density ?? 1)
        if (amountLitres && !Number.isInteger(amountLitres)) {
          amountLitres = Math.floor(Number(amountLitres))
        }
        form.setFieldValue("volume",amountLitres)
        setAmountFuel(amountTonn)
        break  
    }
    calculatePrice(form.getFieldValue("weight"))
  }

  const showOverLimitModal = (
    fuelQuotes: FuelQuoteAddFormValues[],
    limit: number
  ) => {
    const amountSum = fuelQuotes.reduce<number>(
      (p, c) => Number(p) + Number(c.amount),
      Number(form.getFieldValue("amount"))
    )

    if (!isOverLimitApproved && customer && amountSum > customer?.dayLimit) {
      const currentAmount: number = Number(form.getFieldValue("amount"))
      simpleModalComponent.current?.open({
        title: "Предупреждение",
        text: "Превышен лимит доступный к заказу.",
        ok: {
          text: "Продолжить",
          action: () => {
            setIsOverLimitApproved(true)
            simpleModalComponent.current?.close()
          }
        },
        cancel: {
          text: "Отменить",
          action: () => {
            form.setFieldValue(
              "amount",
              limit < customer?.dayLimit - amountSum + currentAmount
                ? limit
                : customer?.dayLimit - amountSum + currentAmount
            )
            simpleModalComponent.current?.close()
          }
        }
      })
    }
  }

  const onFormContinueOrder = async () => {
    try {
      await form.validateFields()
      
      form.setFieldValue("deliveryType", DeliveryType.Pickup)

      setChosenDeliveryType(DeliveryType.Pickup)

      const fuels: FuelQuoteAddFormValues[] =
        form.getFieldValue("fuelQuotes") ?? []
      const orderTransports: TransportAddFormValues[] =
        form.getFieldValue("transports") ?? []

      const totalAmount = fuels.reduce((sum, f) => sum + f.amount, 0)
      const deliveryCost = deliveryAddres?.deliveryCost && deliveryAddres.deliveryCost > 0 ?
        deliveryAddres?.deliveryCost * totalAmount
        : deliveryAddres?.defaultDeliveryCost ?? 0
  
      const fullPrice = fuels.reduce((sum, a) => sum + (Number(a.price) * a.amount), 0) + (+deliveryCost ?? 0)
      setTotalAmount(totalAmount)


      setOrderData({
        date: formatDate(form.getFieldValue("date").toString()),
        customer: {
          id: customer ? customer.id : form.getFieldValue("customerId"),
          name: customer
            ? customer.name
            : customers?.find((c) => c.value == form.getFieldValue("customerId"))
              ?.label ?? "-"
        },
        isReserve: isReserve,
        wishPeriodTime: isTimeUnloadChosen 
        ? timeUnloadFuels.find(t=>t.id == form.getFieldValue("wishTimeSlotId"))?.slot : "-",
        deliveryType:
          deliveryTypes.find(
            (d) => d.type == form.getFieldValue("deliveryType")
          )?.name ?? "Не выбран",
        delivery: isDelivery ? 
        {
          deliveryAddress: deliveryAddres,
          commentary: form.getFieldValue("deliveryCommentary"),
          pump: form.getFieldValue("pump")
        } : undefined ,
        exchangeType:
          chosenDeliveryType != DeliveryType.Delivery
            ? exchangeTypes.find(
              (e) => e.type == form.getFieldValue("exchangeType")
            )?.name ?? "Не выбран"
            : undefined,
        depot:
          depots?.response.find((d) => d.id == form.getFieldValue("depotId"))
            ?.name ?? "Не выбрана",
        depotId: form.getFieldValue("depotId"),
        transports:
          chosenDeliveryType === DeliveryType.Pickup
            ? orderTransports.map((t) => ({
              id: t.driverId,
              driverId: t.driverId,
              driver: allDrivers.find((d) => d.id == t.driverId),
              truckId: t.truckId,
              truck: allTrucks.find((tr) => tr.id == t.truckId),
              pickupId: t.pickupId,
              pickup: allPickups.find((p) => p.id == t.pickupId),
              wishTimeSlot: t.wishTimeSlot,
              fuelAmount: t.fuelAmount 
            }))
            : [],
        fuels: fuels.map((fq) => ({
          id: fq.id,
          quoteId: fq.id,
          amount: fq.amount,
          fuelType: fq.fuelType,
          density: fq.density,
          price: Number(fq.price),
          addon: fuel?.addon ?? 0,
          isFuture: fq.isFuture,
          limitId: fq.limitId
        })),
        isFuture: fuels.some((fq) => fq.isFuture == true),
        deliveryPrice: deliveryCost,
        fullPrice: fullPrice,
        finalPriceText: `Общая сумма: ${getMoney(fullPrice)}`
      })
      setActiveKey("2")
    } catch (errorInfo) { }
  }

  const onAddFuelInOrder = () => {
    if (fuel && amountFuel) {
      const amount = amountFuel
      const fuelQuotes: FuelQuoteAddFormValues[] = form.getFieldValue("fuelQuotes") ?? []
      let fuelInOrder = fuelQuotes.find((fq) => fq.id == fuel.id)

      if (fuelInOrder) {
        fuelInOrder.amount = Number(amount)
        fuelInOrder.price = fuel.price == 0 ? fuel.lastSetPrice : fuel.price
        setTotalAmount(fuelQuotes.reduce((sum, f) => sum + f.amount, 0))
      } 
      else {
        const fuelType = fuelTypes?.find(ft=>ft.id == form.getFieldValue("fuelTypeId")) 

        fuelInOrder = {
          id: fuel.id,
          quoteId: fuel.quoteId,
          amount: amount,
          fuelType: fuelType!.name,
          density: fuel.density,
          price: fuel.price == 0 ? fuel.lastSetPrice : fuel.price,
          addon: fuel.addon,
          isFuture: fuel.isFuture,
          limitId: fuel.limitId ?? 0
        }

        setTotalAmount(prev => prev + amount)
        fuelQuotes.push(fuelInOrder)
      }

      setFullPrice(fuelQuotes.reduce((sum, quote) => sum + (quote.price * quote.amount), 0))

      if (fuel && !fuel.limit) {
        setIsReserve(true)
        form.setFieldValue("isReserve", true)
      }
      else{
        setIsReserve(false)
        form.setFieldValue("isReserve", false)
      }

      refershChosenFuels(fuelQuotes)
      setIsFuelSelect(true)

      if (hasWishTime) {
        const totalAmountFuel = fuelQuotes.reduce((sum, quote) => sum + (convertFuelAmountToLitres(Number(quote.amount ?? 0),quote.density)), 0)
        const totalTransportLiterAmount = totalTransportLiter
        
        checkFuelAmountAndTransportLiter(totalAmountFuel, totalTransportLiterAmount)
      }

      form.setFieldValue("fuelQuotes",fuelQuotes)

      setAmountFuel(0)
      setCannotAddFuel(true)
      form.setFieldValue("fuelTypeId", undefined)
      form.setFieldValue("amount",undefined)
      form.setFieldValue("volume",undefined)
      form.setFieldValue("weight",undefined)
      form.setFieldValue("density", undefined)
      form.setFieldValue("price", undefined)
      setIsFuelLoaded(false)
      setIsAddFuelProcess(false)
      setFuel(undefined)
      setPrice(0)
    }
  }

  const onAddFuelType  = () => {
    if (isAddFuelProcess) {
      setIsAddFuelProcess(false)
      setIsFuelSelect(true)
    }
    else{
      setIsAddFuelProcess(true)
      setIsFuelSelect(false)
    }
  }

  const onDeleteFuel = (key: number) => {
    let fuelQuotes: FuelQuoteAddFormValues[] =
      form.getFieldValue("fuelQuotes") ?? []
      const deleteFuel = chosenFuels.find(f=>f.id == key)
      setFullPrice(fullPrice-(deleteFuel!.price * deleteFuel!.amount ))
      fuelQuotes = fuelQuotes.filter((fq) => fq.id != key)

    setTotalAmount(prev => prev - (deleteFuel?.amount ?? 0))
    refershChosenFuels(fuelQuotes)
    form.setFieldValue("fuelQuotes", fuelQuotes)
    setIsAddFuelProcess(false)
    setPrice(0)
    setFuel(undefined)
    if (fuelQuotes.length > 0) {
      setIsFuelSelect(true)
    }
    else {
      setIsFuelSelect(false)
    }

    if (hasWishTime) {
      const totalAmountFuel = fuelQuotes.reduce((sum, quote) => sum + (convertFuelAmountToLitres(Number(quote.amount ?? 0),quote.density)), 0)
      const totalTransportLiterAmount = totalTransportLiter
      setTotalTransportLiter(totalTransportLiterAmount)

      checkFuelAmountAndTransportLiter(totalAmountFuel, totalTransportLiterAmount)
    }
  }

  const checkFuelAmountAndTransportLiter = ( 
    totalAmountFuel: number,
     totalTransportLiterAmount: number ) => {
      totalAmountFuel = formatFuelVolume(totalAmountFuel)

      if (totalAmountFuel > totalTransportLiterAmount){
        setTransportLiterErrorMessage("Количество топлива больше, чем объем траснпортного средства")
        setIsChosenTransportLiterOk(false)
      } else {
        setTransportLiterErrorMessage(null)
        setIsChosenTransportLiterOk(true)
      }
  }

  const refreshChosenTransports = (ts: TransportAddFormValues[]) => {
    if (allDrivers.length == 0 || !transports?.response) {
      return
    }

    setChosenTransports(
      ts.map((t) => ({
        id: t.driverId,
        driverId: t.driverId,
        driver: allDrivers.find((d) => d.id == t.driverId),
        truckId: t.truckId,
        truck: allTrucks.find((tr) => tr.id == t.truckId),
        pickupId: t.pickupId,
        pickup: allPickups.find((p) => p.id == t.pickupId),
        wishTimeSlot: t.wishTimeSlot,
        fuelAmount: t.fuelAmount
      }))
    )
  }

  const refershChosenFuels = (fuelQuotes: FuelQuoteAddFormValues[]) => {
    setChosenFuels(
      fuelQuotes.map((fq) => ({
        id: fq.id,
        quoteId: fq.id,
        amount: fq.amount,
        fuelType: fq.fuelType,
        density: fq.density,
        price: Number(fq.price),
        addon: fq.addon,
        isFuture: fq.isFuture,
        limitId: fq.limitId
      }))
    )

    setChosenDepot(
      depots?.response.find((d) => d.id == form.getFieldValue("depotId"))
        ?.name ?? undefined
    )
  }

  const onCreateOrder = () => {
    form.submit() 
  }

  const additionalTransportColumns = [
    {
      title: "",
      dataIndex: "delete",
      render: (_: any, transport: ITranportPairOrder) =>
        activeKey == "1" ? (
          <Button
            danger
            type="primary"
            onClick={() => onDeleteTransport(transport)}
          >
            X
          </Button>
        ) : null
    }
  ]

  const additionalFuelColumns = [
    {
      title: "",
      dataIndex: "delete",
      render: (_: any, fuel: IOrderFuel) =>
        activeKey == "1" ? (
          <Button danger type="primary" disabled = {isOrderOnClientApprovingTransportStatus} onClick={() => onDeleteFuel(fuel.id)}>
            X
          </Button>
        ) : null
    }
  ]

  const disabledDate: AntDatePickerProps["disabledDate"] = (current) => {
    return current < dayjs().add(-1, "day")
  }

  const onReturnToChangeOrderBtn = () => {
    setActiveKey("1")
    setFuel(undefined)
  }


  const tabsItems : TabsProps["items"] = [
    {
      key: "1",
      label: "Оформление заказа",
      children:
          <>
          <DeliveryAddressesAddForm 
           open={()=>addressComponent.current?.open()}
           close={()=>addressComponent.current?.close()} 
           onCloseAction={()=> deliveryForm.current?.refetch() }
           ref={addressComponent} />
          <Row>
            <Col>
            <Form.Item
            hidden={ true }
            name="deliveryType">
              <Input
                value={ isDelivery ? DeliveryType.Delivery : DeliveryType.Pickup } />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
              hidden={ true }
              name="isReserve"
              valuePropName="checked">
                <Checkbox value={ isReserve } />
              </Form.Item>
            </Col>
          </Row>
           <Row hidden={isCustomerOrder || isEditByCustomer} gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                    name="customerId"
                    label="Клиент"
                    rules={[
                      {
                        required: !isCustomerOrder,
                        message: "Пожалуйста выберите клиента"
                      }
                    ]}
                >
                  <Select
                      disabled={id != 0}
                      placeholder="Клиент"
                      onChange={onCustomerChange}
                      showSearch
                      filterOption={(inputValue, option) =>
                          filterSelectOption(inputValue, option?.label || "")
                      }
                      options={customers}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
                hidden={customer == undefined || isEditByCustomer || id == 0}
                className={classes["order-row"]}
                gutter={16}
            >
              <Col xs={24} md={12}>
                <Row gutter={[12, 6]}>
                  <Col span={24}>
                    <Text type="secondary">Заказчик:</Text>
                  </Col>
                  <Col span={24}>
                    <Text className={classes["f16"]}>{customer?.name}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={16} hidden={isEditByCustomer }>
              <Col xs={24} md={12}>
                <Form.Item 
                  name={"depotId"}
                  label= "Нефтебаза"
                  rules={[
                    {
                      required : true,
                      message : "Пожалуйста выберите нефтебазу"
                    }
                  ]}>
                    <Select 
                      disabled= {isFuelSelect || isAddFuelProcess}
                      placeholder = "Нефтебаза"
                      showSearch
                      onChange={(value, options) => { onDepotChange(value, options)}}
                      filterOption={(inputValue, option) =>
                        filterSelectOption(
                            inputValue,
                            option?.label || ""
                        )
                    }
                    options={depots?.response.map((depot) => ({
                      value: depot.id,
                      label: depot.name
                    }))}>
                    </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                    name="date"
                    label="Дата"
                    rules={[
                      { required: true, message: "Пожалуйста выберите дату" }
                    ]}
                >
                  <DatePicker
                      disabled= {isFuelSelect || isAddFuelProcess}
                      style={{ width: "100%" }}
                      placeholder="Дата заказа"
                      disabledDate={disabledDate}
                      onChange={onDateChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} hidden={isEditByCustomer}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="fuelTypeId"
                  label="Тип топлива"
                  >
                    <Select
                      disabled= {isFuelSelect && !isAddFuelProcess}
                      onChange={fuelTypeChange}
                      placeholder="Тип топлива"
                      showSearch
                      filterOption={(inputValue, option) =>
                        filterSelectOption(
                          inputValue,
                          option?.label || ""
                        )
                      }
                      options={fuelTypes?.map((fuelType) => ({
                        value: fuelType.id,
                        label: `${fuelType.name}`
                    }))}
                    />
                    </Form.Item>
                </Col>
              <Col xs={24} md={12}>
                <Form.Item
                    hidden={isDelivery}
                    name="exchangeType"
                    label="Вид обменной доверенности"
                    rules={[
                      {
                        required: !isDelivery,
                        message:
                            "Пожалуйста выберите Вид обменной доверенности"
                      }
                    ]}
                >
                  <Select
                      disabled={isDelivery}
                      placeholder="Вид обменной доверенности"
                      showSearch
                      filterOption={(inputValue, option) =>
                          filterSelectOption(inputValue, option?.label || "")
                      }
                      options={exchangeTypes.map((type) => ({
                        value: type.type,
                        label: type.name
                      }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            {(chosenFuels && chosenFuels.length > 0) || isFuelLoaded  ? (
              <>
              <Row gutter={16} hidden={!isFuelLoaded}>
                <Col xs={22}>
                {
                    (fuel && fuel.price > 0
                      ? ` ${getMoney(fuel.price)} за 1 тонну`
                      : `Цена не установлена, последнее установленное значение: 
                      ${getMoney(fuel?.lastSetPrice ?? 0)} за 1 тонн`)}
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item label="Объем, л." name="volume">
                    <InputNumber
                      className={classes["inputNumber"]}
                      onChange={() => onWeightOrVolumeChange(InputValueOrWeight.volume)}
                      type="number"
                      placeholder="Объем"
                      min={1}
                      step={1}/>
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item label="Масса, т." name="weight">
                    <InputNumber
                      className={classes["inputNumber"]}
                      onChange={(e) => onWeightOrVolumeChange(InputValueOrWeight.weight)}
                      type="number"
                      placeholder="Масса"
                      min={1}
                      />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6} style={{ display: "flex", alignItems: "flex-end" }}>
                  <Button
                    disabled={price === 0 }
                    className={classes["align-center"]}
                    type="primary"
                    size="large"
                    shape="round"
                    onClick={onAddFuelInOrder}>
                      Добавить{price > 0 ? ` (${getMoney(price)})` : ""}
                    </Button>
                </Col>
              </Row>
              <div
                  hidden={!chosenFuels || chosenFuels.length == 0}
                  className={classes["order-row"]}
              >
                <OrderFuelTable
                    additionalColumns={additionalFuelColumns}
                    fuels={chosenFuels}
                />
              </div>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} className={classes["col-flex"]}>
                  <Text strong>Итого: {getMoney(fullPrice)} </Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} className={classes["col-flex"]}>
                    <Button
                       hidden={!chosenFuels || chosenFuels.length == 0 || isEditByCustomer}
                        className={classes["add-fuel-btn"]}
                        type="primary"
                        size="large"
                        shape="round"
                        onClick={onAddFuelType}
                    >
                      {isAddFuelProcess ? "отменить добавление" : "добавить топливо"}
                    </Button>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {fuel && fuel.price < 1  && (
                  <Col span={24}>
                    <Alert message="На выбранную дату не установлена цена на топливо. Расчет будет произведен по последней установленной цене"  type="warning" showIcon closable />
                  </Col>
                )}
                { fuel && !fuel.limit && (
                  <Col span={24}>
                    <Alert message="Лимит топлива на выбранную дату не установлен, заявка будет оформлена как резервирование топлива"  type="warning" showIcon closable />
                  </Col>
                )}
                { fuel && fuel.limit && fuel.limit < (radioFeulMeasurement == "litr" ? convertFuelAmountToTonn(amountFuel, fuel.density) : amountFuel) && (
                  <Col span={24}>
                    <Alert message="Указанный объем превышает доступный лимит. Заявка будет передана в обработку администратору"  type="warning" showIcon closable />
                  </Col>
                )}
                { customer && customer.availableSum < price && (
                  <Col span={24}>
                    <Alert message="Недостаточно средств, доступных для заказа. Заявка будет иметь меньший приоритет при обработке"  type="warning" showIcon closable />
                  </Col>
                )}
              </Row>
            </>
            ) : <Alert message="Выберите нефтебазу, тип топлива и дату" type="warning" showIcon closable />}
            <Row gutter={16} className={classes["order-row-balance"]}>
              <Col xs={24} md={12}>
                <Text strong> Сумма доступная для заказа: { getMoney(customer?.availableSum ?? 0) } </Text>
              </Col>
            </Row>
            <div >
              { isDelivery && (
              <Collapse 
              className={classes["collapse-order"]}
              ghost
              defaultActiveKey={["1"]}
              items={[
                { key: 1,
                  label:"Доставка",
                  children: (
                    <>
                      {
                        <DeliveryForm
                        ref={deliveryForm}
                        initialValue={order ? 
                          { 
                            delivery: order?.response.delivery, 
                            deliveryCost: order?.response.delivery.deliveryAddress?.deliveryCost } : undefined}
                        addDeliveryAddresComponent={addressComponent}
                        form={form}
                        isActive ={isDelivery} 
                        customerId={customer?.id ?? 0}
                        fuelsTotalAmount={totalAmount}
                        setAddress={(addres: IDeliveryAddress)=>setDeliveryAddres(addres)} />}
                    </>
                  )
                }
              ]}
              >
              </Collapse>
            )}
            {!isDelivery && (
              <div className={classes["order-row"]}>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item 
                  name="driverId"
                   label="Водитель"
                   rules={[
                    {
                      required: hasWishTime && chosenTransports && chosenTransports.length == 0,
                      message: "Пожалуйста выберите водителя"
                    }
                   ]} >
                    <Select
                        disabled = {isTransportSelect}
                        onChange={(e: number) =>
                            setIsDriverChosen(e != undefined)
                        }
                        placeholder="Водитель"
                        showSearch
                        filterOption={(inputValue, option) =>
                            filterSelectOption(
                                inputValue,
                                option?.label || ""
                            )
                        }
                        options={allDrivers
                            .filter(
                                (d) =>
                                    !chosenTransports.some(
                                        (ct) => ct.driverId == d.id
                                    )
                            )
                            .map((driver) => ({
                              value: driver.id,
                              label: driver.fullName
                            }))}
                        dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                Нет водителя в списке? Добавьте в справочнике водителей!
                              </Space>
                            </>
                        )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item 
                   name="truckId"
                   label="Транспорт"
                   rules={[
                    {
                      required: hasWishTime && chosenTransports && chosenTransports.length == 0,
                      message: "Пожалуйста выберите транспорт"
                    }
                   ]}>
                    <Select
                    disabled = {isTransportSelect}
                        onChange={(e: number) => setIsTruckChosen(e != undefined) }
                        placeholder="Транспорт"
                        showSearch
                        filterOption={(inputValue, option) =>
                            filterSelectOption(
                                inputValue,
                                option?.label || ""
                            )
                        }
                        options={allTrucks
                            .filter(
                                (tr) =>
                                    !chosenTransports.some(
                                        (ct) => ct.truckId == tr.id
                                    )
                            )
                            .map((transport) => ({
                              value: transport.id,
                              label: formatTransport(transport)
                            }))}
                        dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                Нет транспорта в списке? Добавьте в справочнике транспорта!
                              </Space>
                            </>
                        )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col xs={24} md={12}>
                  <Form.Item name="pickupId" label="Прицеп">
                    <Select
                        disabled = {isTransportSelect}
                        placeholder="Прицеп"
                        showSearch
                        filterOption={(inputValue, option) =>
                            filterSelectOption(
                                inputValue,
                                option?.label || ""
                            )
                        }
                        options={allPickups
                            .filter(
                                (p) =>
                                    !chosenTransports.some(
                                        (ct) => ct.pickupId == p.id
                                    )
                            )
                            .map((transport) => ({
                              value: transport.id,
                              label: formatTransport(transport)
                            }))}
                        dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                Нет прицепа в списке? Добавьте в справочнике транспорта!
                              </Space>
                            </>
                        )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                <Form.Item
                    hidden={!hasWishTime}
                    name="wishTimeSlotId"
                    label="Желаемый период загрузки"
                    rules={[
                      {
                        required : hasWishTime && chosenTransports && chosenTransports.length == 0 ,
                        message : "Пожалуйста выберите время отгрузки"
                      }
                    ]}
                >
                  <Select
                    disabled= {isTransportSelect}
                    placeholder="Желаемый период загрузки"
                    showSearch
                    onChange={timeUnloadChange}
                    filterOption={(inputValue, option) =>
                      filterSelectOption(
                        inputValue,
                        option?.label || "")}
                    options={timeUnloadFuels.map((timeUnload) => ({
                      value: timeUnload.id,
                      label: `${timeUnload.slot}`
                    }))}
                  />
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={16}>
              <Col
                    xs={24}
                    md={12}
                    className={classes["col-flex"]}
                >
                  <Button
                      disabled={ (chosenTransports.length == 1 && chosenFuels.length > 0) ||!isDriverChosen || !isTruckChosen 
                        || (hasWishTime && !isTimeUnloadChosen)}
                      className={classes["align-center"]}
                      type="primary"
                      size="large"
                      shape="round"
                      onClick={onAddTransportInOrder}
                  >
                    Добавить
                  </Button>
                </Col>
              </Row>

              <div
                  hidden={
                      chosenTransports == undefined ||
                      chosenTransports.length == 0
                  }
                  className={classes["order-row"]}
              >
                <TransportTable
                    orderDate={orderDate}
                    additionalColumns={additionalTransportColumns}
                    transports={chosenTransports}
                    canApprove={false}
                />
                {!isChosenTransportLiterOk && transportLiterErrorMessage && 
                  (<Row gutter={16} className={classes["order-row-balance"]}> 
                    <Col>
                     <Alert message={transportLiterErrorMessage} type="error" showIcon />
                    </Col>
                    </Row>)}
              </div>
            </div>
            )}
            </div>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Button
                    disabled={ (hasWishTime && !isChosenTransportLiterOk && !isDelivery) || chosenFuels.length == 0 }
                    type="primary"
                    size="large"
                    shape="round"
                    onClick={onFormContinueOrder} >
                  {formOrderText}
                </Button>
              </Col>
            </Row>
          </>,
      icon: <ProfileOutlined />
    },
    {
      key: "2",
      label: "Подтверждение заказа",
      children:
          <>
            <Row className={classes["order-row"]} gutter={16} hidden={isEditByCustomer}>
              <Col xs={24} md={12}>
                <Row gutter={[12, 6]}>
                  <Col span={24}>
                    <Text type="secondary">Заказчик:</Text>
                  </Col>
                  <Col span={24}>
                    <Text className={classes["f16"]}>
                      {orderData?.customer.name}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className={classes["order-row"]} gutter={16} hidden={isEditByCustomer}>
              <Col xs={24} md={12}>
                <Row gutter={[12, 6]}>
                  <Col span={24}>
                    <Text type="secondary">Дата:</Text>
                  </Col>
                  <Col span={24}>
                    <Text className={classes["f16"]}>{orderData?.date}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={classes["order-row"]} gutter={16} hidden={isEditByCustomer}>
              <Col hidden={orderData?.delivery != undefined} xs={24} md={12}>
                <Row gutter={[12, 6]}>
                  <Col span={24}>
                    <Text type="secondary">Вид обменной доверенности:</Text>
                  </Col>
                  <Col span={24}>
                    <Text className={classes["f16"]}>
                      {orderData?.exchangeType}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div>
            <Collapse
                className={classes["collapse-order"]}
                defaultActiveKey={["1"]}
                ghost
                items={[
                  {
                    key: "1",
                    label: "Выбор топлива",
                    children: (
                        <>
                          <Row className={classes["order-row"]} gutter={16}>
                            <Col span={24}>
                              <Row gutter={[12, 6]}>
                                <Col span={24}>
                                  <Text type="secondary">Нефтебаза:</Text>
                                </Col>
                                <Col span={24}>
                                  <Text className={classes["f16"]}>
                                    {orderData?.depot}
                                  </Text>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <div
                              hidden={
                                  orderData?.fuels && orderData?.fuels.length == 0
                              }
                              className={classes["order-row"]}
                          >
                            <OrderFuelTable fuels={orderData?.fuels ?? []} />
                          </div>
                        </>
                    )
                  }
                ]}
            ></Collapse>
              {!isDelivery && (
                <Collapse
                className={classes["collapse-order"]}
                defaultActiveKey={["1"]}
                ghost
                items={[
                  {
                    key: "1",
                    label: "Выбор транспорта",
                    children: (
                        <>
                          <div
                              hidden={
                                  orderData?.transports &&
                                  orderData?.transports.length == 0
                              }
                              className={classes["order-row"]}
                          >
                            <TransportTable
                                orderDate={orderDate}
                                transports={orderData?.transports ?? []}
                                canApprove={false}
                            />
                          </div>
                          <Text
                              hidden={
                                  !orderData?.transports ||
                                  orderData?.transports.length > 0
                              }
                          >
                            Не выбрано
                          </Text>
                        </>
                    )
                  }
                ]}
            ></Collapse>
              )}
              {isDelivery && (
                <Collapse
                className={classes["collapse-order"]}
                defaultActiveKey={["1"]}
                ghost
                items={[
                  {
                    key: "1",
                    label: "Доставка",
                    children: (
                        <>
                          <Row className={classes["order-row"]} gutter={16}>
                            <Col span={24}>
                              <OrderDeliveryTable
                               deliveryDefaultPrice={(orderData?.delivery?.deliveryAddress?.defaultDeliveryCost ?? 0) * totalAmount}
                               delivery={orderData?.delivery}
                               showPrice={true}
                               deliveryPrice={(orderData?.delivery?.deliveryAddress?.deliveryCost ?? 0) * totalAmount}/>
                            </Col>
                          </Row >
                        </>
                    )
                  }
                ]}
            ></Collapse>
              )}
            </div>
            <Row
                className={classes["order-row"]}
                gutter={16}
            >
              <Col xs={24} md={12}>
                <Text className={classes["f16"]}>
                  {orderData?.finalPriceText}
                </Text>
              </Col>
            </Row>
            <Flex className={classes["order-row"]} gap="middle">
              <Button
                  type="primary"
                  size="large"
                  shape="round"
                  loading={isProcessing}
                  onClick={onCreateOrder}
              >
                {orderText}
              </Button>
              <Button
                  type="primary"
                  ghost
                  size="large"
                  shape="round"
                  onClick={onReturnToChangeOrderBtn}
              >
                Изменить
              </Button>
            </Flex>
          </>,
      icon: <IdcardOutlined />
    }
  ]

  if(hasPermission(Resource.Order,Scope.AllActions)){
    tabsItems.push({
      key: "3",
      label: "Создать заказ задним числом",
      children: <BackdatingOrderAddForm isOpen={isOpen} onClose={onCloseHandler}/> 
    })
  }

  return (
    <Drawer
      title={title}
      width={820}
      onClose={onCloseHandler}
      open={isOpen}
      maskClosable={false}
      styles={{ body: { paddingBottom: 80 } }}
    >
      {notificationContextHolder}
      {contextHolder}
      <SimpleModal ref={simpleModalComponent}></SimpleModal>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Text>{modalText}</Text>
      </Modal>

      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Spin
          spinning={depotsIsLoading && driversIsLoading && transportsIsLoading}
        >
          <Tabs  defaultActiveKey="1"  activeKey={activeKey}  items={tabsItems} 
          onChange={(e)=>{
            if (e == "3" || e == "1") {
              setActiveKey(e)
            }
          }} />
        </Spin>
      </Form>
    </Drawer>
  )
})
