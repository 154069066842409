import React from "react"

import { OidcConfiguration, OidcProvider } from "@axa-fr/react-oidc"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ConfigProvider } from "antd"
import ruRU from "antd/locale/ru_RU"
import dayjs from "dayjs"
import "dayjs/locale/ru"
import { BrowserRouter } from "react-router-dom"


import variables from "@/_styles/variables.module.scss"
import { CustomerContextProvider } from "@/context/CustomerContext"
import { PermissionContextProvider } from "@/context/PermissionContext"
import { ErrorBoundary } from "@/hoc/ErrorBoundary"
import { AuthorizationCallBackSuccess } from "@/pages/AuthorizationCallBackSuccess"
import { Router } from "@/router"

import { NotificationProvider } from "./context/NotificationContext"
import { SignalRProvider } from "./context/SignalRContext" 

const configuration: OidcConfiguration = {
  client_id: process.env.REACT_APP_CLIENT_ID ?? "",
  redirect_uri: window.location.origin + "/authentication/callback",
  silent_redirect_uri: window.location.origin + "/authentication/silent-callback",
  scope: "openid profile email offline_access",
  authority: process.env.REACT_APP_AUTHORITY ?? "",
  service_worker_only: false,
  storage: localStorage,
  token_request_extras: {
    client_secret: process.env.REACT_APP_CLIENT_SECRET ?? ""
  }
}

const client = new QueryClient()

dayjs.locale("ru")

export const App = () => {
  return (
    <OidcProvider
      configuration={configuration}
      callbackSuccessComponent={AuthorizationCallBackSuccess}>
      <QueryClientProvider client={client}>
        <ConfigProvider
          locale={ruRU}
          theme={{
            token: {
              colorPrimary: variables.basecolor,
              colorBgLayout: "#ffffff"
            },
            components: {
              Layout: {
                siderBg: variables.basecolordark
              },
              Menu: {
                darkItemBg: variables.basecolordark,
                darkSubMenuItemBg: variables.basecolordark,
                darkItemSelectedBg: variables.basecolor
              },
              Segmented: {
                itemSelectedBg: variables.basecolor,
                itemSelectedColor: "#ffffff"
              },
              DatePicker: {
                timeColumnWidth: 100
              }
            }
          }}
        >
          <PermissionContextProvider>
            <CustomerContextProvider>
              <SignalRProvider>
                <NotificationProvider>
                  <React.StrictMode>
                    <ErrorBoundary>
                      <BrowserRouter>
                      <Router />
                    </BrowserRouter>
                  </ErrorBoundary>
                 </React.StrictMode>
                </NotificationProvider>
              </SignalRProvider>
            </CustomerContextProvider>
          </PermissionContextProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </OidcProvider>
  )
}
