import { FC, useState, useMemo,useRef, ChangeEvent,useEffect } from "react"

import { Segmented, Row, Flex, Form, Input, InputNumber, Button, TimePicker, Typography, Col,Select,DatePicker,Radio, RadioChangeEvent,Alert, Divider,Space  } from "antd"
import dayjs from "dayjs"

import { SimpleModal, SimpleModalRef } from "@/components/_Shared/SimpleModal"
import { TransportTable } from "@/components/Transport/TransportTable"
import { getFuelForOrder } from "@/http/fuel"
import { getFuelTypeByDepot } from "@/http/fuelType"

import classes from "./BackdatingOrderAddForm.module.scss"
import { optionsRadioFuel, orderBackDatingStatus } from "./BackdatingOrderAddFormColumns"
import { FuelQuoteAddFormValues, OrderAddFormValues, TransportAddFormValues } from "../OrderAddForm"
import { OrderFuelTable } from "../OrderFuelTable"

import { useCustomersQuery } from "@/hook/Dictionaries/Customers/useCustomerQuery"
import { useDepotSelectListQuery } from "@/hook/Dictionaries/useDepotQuery"
import { useDriverForOrderQuery } from "@/hook/Dictionaries/useDriverQuery"
import { useTransportForOrderQuery } from "@/hook/Dictionaries/useTransportQuery"
import { useOrderMutation } from "@/hook/Orders/useOrderMutation"
import { useTimeUnloadFuelMutation } from "@/hook/TimeUnloadFuel/useTimeUnloadFuelMutation"

import { ICustomer } from "@/types/ICustomer"
import { IDriver } from "@/types/IDriver"
import { convertFuelAmountToLitres, convertFuelAmountToTonn, getTransformedFuelDensity, IFuelOrder } from "@/types/IFuel"
import { IFuelType } from "@/types/IFuelType"
import { DeliveryType, exchangeTypes, IOrderCreate, IOrderFuel, ITranportPairOrder, OrderStatus } from "@/types/IOrder"
import { ITimeUnloadFuel } from "@/types/ITimeUnloadFuel"
import { ITransport, ITransportClass } from "@/types/ITransport"

import { getMoney } from "@/utils/formatNumber"
import { formatTransport } from "@/utils/formatString"


const { Text } = Typography

interface IBackdatingOrderAddFormProps{
  onClose: ()=> void
  isOpen: boolean
}

export const BackdatingOrderAddForm: FC<IBackdatingOrderAddFormProps> = (props) => {

  const { onClose, isOpen } = props

  const [form] = Form.useForm<OrderAddFormValues>()
  const [backdatingStatus, setBackdatingStatus] = useState<OrderStatus>(OrderStatus.Closed)
  const [hasWishTime, setHasWishTime] = useState<boolean>(false) 
  const [customer, setCustomer] = useState<ICustomer>()
  const [isReserve, setIsReserve] = useState<boolean>(false)

  const [density, setDensity] = useState<number>(0)
  const [fuelPrice, setFuelPrice] = useState<number>(0)
  const [isDriverChosen, setIsDriverChosen] = useState(false)
  const [isTruckChosen, setIsTruckChosen] = useState(false)
  const [isTimeUnloadChosen, setIsTimeUnloadChosen] = useState(false)
  const [radioFeulMeasurement, setRadioFeulMeasurement ] = useState<string>("litr")
  const [orderDate, setOrderDate] = useState<Date | undefined>(undefined)
 
  const [fuelTypes, setFuelTypes] = useState<IFuelType[]>()
  const [timeUnloadFuels, setTimeUnloadFuels] = useState<ITimeUnloadFuel[]>([])
  const [fuel, setFuel] = useState<IFuelOrder>()
  const [isFuelLoaded, setIsFuelLoaded] = useState<boolean>(false)
  const [amountFuel, setAmountFuel] = useState<number>(0)
  const [cannotAddFuel, setCannotAddFuel] = useState(true)
  const [chosenTransports, setChosenTransports] = useState<ITranportPairOrder[]>([])
  const [isTransportSelect, setIsTransportSelect] = useState<boolean>(false)
  const [isFuelSelect, setIsFuelSelect] = useState<boolean>(false)
  const [chosenFuels, setChosenFuels] = useState<IOrderFuel[]>([])
  const [price, setPrice] = useState(0)
  const [fullPrice, setFullPrice] = useState(0)
  const [isOrderOnClientApprovingTransportStatus, setIsOrderOnClientApprovingTransportStatus] = useState<boolean>(false)
  const [isAddFuelProcess, setIsAddFuelProcess] = useState<boolean>(false)
  const [densityOnView, setDensityOnView] = useState<string>("0")

  const { data: drivers, isLoading: driversIsLoading } = useDriverForOrderQuery(0, customer?.id)
  const { data: transports, isLoading: transportsIsLoading } = useTransportForOrderQuery(0, customer?.id)
  const { createMutation } = useOrderMutation()
  const { data: customersData } = useCustomersQuery()
  const { data: depots } = useDepotSelectListQuery()
  const { getAvailableTimeUnloadFuelsMutation, createTimeUnloadFuelsMutation } = useTimeUnloadFuelMutation()

  const simpleModalComponent = useRef<SimpleModalRef>(null)

  useEffect(() => {
    if (!isOpen) {
      onCloseHandler()
    }
  }, [ isOpen ])

  const densityText = useMemo(()=>{
    if (density <= 0){
      return "Плотность: Неверно указаны данные"
    }
    else {
      return `Плотность: ${getTransformedFuelDensity(density)}`     
    }
  },[density])



  const allDrivers: IDriver[] = useMemo(() => {
        if (drivers?.response) {
          return drivers.response
        }
        return []
      }, [drivers, driversIsLoading])

      const allTrucks: ITransport[] = useMemo(() => {
        if (transports?.response) {
          return transports.response.filter((t)=>t.class == ITransportClass.Truck)
        }
        return []
      }, [transports, transportsIsLoading])

      const allPickups: ITransport[] = useMemo(() => {
        if (transports?.response) {
          return transports.response.filter((t)=>t.class == ITransportClass.Pickup)
        }
        return []
      }, [transports, transportsIsLoading])

    const additionalFuelColumns = [
        {
          title: "",
          dataIndex: "delete",
          render: (_: any, fuel: IOrderFuel) =>
            (<Button danger type="primary" disabled = {isOrderOnClientApprovingTransportStatus} onClick={() => onDeleteFuel(fuel.id)}>
                X
              </Button>)
        }
      ]

      const additionalTransportColumns = [
        {
          title: "",
          dataIndex: "delete",
          render: (_: any, transport: ITranportPairOrder) =>
            (
              <Button
                danger
                type="primary"
                onClick={() => onDeleteTransport(transport)}
              >
                X
              </Button>
            ) 
        }
      ]

      const onCloseHandler = ()=>{
        onClose()
        form.resetFields()
        setCustomer(undefined)
        setPrice(0)
        setFullPrice(0)
        setFuel(undefined)
        setHasWishTime(false)
        setChosenTransports([])
        setChosenFuels([])
        setIsDriverChosen(false)
        setIsTruckChosen(false)
        setIsTimeUnloadChosen(false)
        setIsReserve(false)
        setHasWishTime(false)
        setIsFuelSelect(false)
        setIsTransportSelect(false)
        setTimeUnloadFuels([])
        setIsAddFuelProcess(false)
        setOrderDate(undefined)
        setFuelPrice(0)
        setDensity(0)
        setIsFuelLoaded(false)
      }

      const onDeleteTransport = (transport: ITranportPairOrder) => {
        let orderTransports: TransportAddFormValues[] =
          form.getFieldValue("transports") ?? []
        orderTransports = orderTransports.filter((ot) => ot.driverId != transport.id)
    
        refreshChosenTransports(orderTransports)
        form.setFieldValue("transports", orderTransports)
        form.setFieldValue("wishTimeSlot", undefined)
        form.setFieldValue("wishTimeSlotId",undefined)
        setIsTransportSelect(false)
        setIsTimeUnloadChosen(false)    
      }

      const onDeleteFuel = (key: number) => {
        let fuelQuotes: FuelQuoteAddFormValues[] =
          form.getFieldValue("fuelQuotes") ?? []
          const deleteFuel = chosenFuels.find(f=>f.id == key)
          setFullPrice(fullPrice-(deleteFuel!.price * deleteFuel!.amount ))
          fuelQuotes = fuelQuotes.filter((fq) => fq.id != key)
    
        refershChosenFuels(fuelQuotes)
        form.setFieldValue("fuelQuotes", fuelQuotes)
        setIsAddFuelProcess(false)
        setPrice(0)
        setFuel(undefined)
        if (fuelQuotes.length > 0) {
          setIsFuelSelect(true)
        }
        else {
          setIsFuelSelect(false)
        }
      }

    const onDepotChange = (value: any, options: any) => {
        if (depots) {
          const depot = depots.response.find(
            (d) => d.id == form.getFieldValue("depotId")
          )
    
          if (depot) {
            uploadFuelTypes(depot.id)
            setHasWishTime(depot.hasWishTime)
          }
    
          if (depot?.hasWishTime) {
            setHasWishTime(true)
          }
          else {
            setHasWishTime(false)
          }
        }
      }

    const uploadFuelTypes = (depotId: number) => {
        if (depotId) {
          getFuelTypeByDepot(depotId).then(res =>{
            setFuelTypes(res.response ?? null)
          })
        }
    }

    const onDateChange = (e: any, dateString: string | string[]) => {
        setOrderDate(new Date(dayjs(form.getFieldValue("date")).format("YYYY-MM-DD")))
        fuelLoad()
    }

    const refreshChosenTransports = (ts: TransportAddFormValues[]) => {
        if (allDrivers.length == 0 || !transports?.response) {
          return
        }
    
        setChosenTransports(
          ts.map((t) => ({
            id: t.driverId,
            driverId: t.driverId,
            driver: allDrivers.find((d) => d.id == t.driverId),
            truckId: t.truckId,
            truck: allTrucks.find((tr) => tr.id == t.truckId),
            pickupId: t.pickupId,
            pickup: allPickups.find((p) => p.id == t.pickupId),
            wishTimeSlot: t.wishTimeSlot,
            fuelAmount: t.fuelAmount
          }))
        )
    }

      const onCustomerChange = (customerId: number) => {
        setCustomer(customersData?.response.data.find(c => c.id == customerId))
    
        refreshChosenTransports([])
        form.setFieldValue("transports", [])
        form.setFieldValue("driverId", undefined)
        form.setFieldValue("truckId", undefined)
        form.setFieldValue("pickupId", undefined)
        setIsDriverChosen(false)
        setIsTruckChosen(false)
        setIsTimeUnloadChosen(false)
      }


      const fuelTypeChange = () => {
        const fuelTypeId = form.getFieldValue("fuelTypeId")
    
        const fuelQuotes: FuelQuoteAddFormValues[] = form.getFieldValue("fuelQuotes") ?? []
        const fuelInOrder = fuelQuotes.find((fq) => fq.fuelType == fuelTypes?.find(ft=>ft.id == fuelTypeId)?.name)
    
        if (fuelInOrder) {
          setCannotAddFuel(true)
          form.setFieldValue("density", undefined)
          form.setFieldValue("price", undefined)
          setIsFuelLoaded(false)
          setIsAddFuelProcess(true)
          simpleModalComponent.current?.open({
            title: "Предупреждение",
            text: "Выбранный тип топлива уже добавлен. Обновить данные по топливу?",
            ok: {
              text: "Обновить",
              action: () => {
                fuelLoad()
                simpleModalComponent.current?.close()
              }
            },
            cancel: {
              text: "Выбрать другой тип топлива",
              action: () => {
                simpleModalComponent.current?.close()
              }
            }
          })
        }
        else{
          fuelLoad()
        }
      }


    const calculatePrice = (amount: number) => {
    if (amount && amount > 0) {
      if (radioFeulMeasurement == "ton") {
        if (fuelPrice > 0) {
          const newPrice = amount * fuelPrice
          setPrice(newPrice)
        }
      }
      else if (radioFeulMeasurement == "litr"){
        if (fuelPrice > 0) {
          const newPrice = convertFuelAmountToTonn(amount, form.getFieldValue("density")) * fuelPrice
          setPrice(newPrice)
        }
      }  
    }
    else{
      setPrice(0)
      setAmountFuel(amount ?? 0)
    }
  }

  const fuelLoad = () => {
    const depotId = form.getFieldValue("depotId")
    const date = form.getFieldValue("date")
    const fuelTypeId = form.getFieldValue("fuelTypeId")
    
    if (depotId && date && fuelTypeId) {
      getFuelForOrder({
        Date: new Date(dayjs(date).format("YYYY-MM-DD")),
        FuelTypeId : fuelTypeId,
        DepotId : depotId,
        CustomerId: form.getFieldValue("customerId")
      }).then(res => {
        setFuel(res.response)
        setIsFuelLoaded(true)
      })
    }
  }

  const onWeightOrVolumeChange = () => {
    const weight = form.getFieldValue("tonn")
    const volume = form.getFieldValue("litr")

    if (!weight || !volume) {
      setDensity(0)
      return
    }

    if (weight <= 0 || volume <= 0) {
      setDensity(0)
      return
    }

    const calculateDenstiy = Math.round(weight / volume * 1000 * 1000) //для хранения целым числом
    setDensity(calculateDenstiy)
    calculateFinalPrice(fuelPrice,calculateDenstiy)
  }

  const calculateFinalPrice = (fuelPrice:number, density:number) =>{
    if (density < 0 || fuelPrice < 0) {
      setDensity(0)
      setPrice(0)
      return
    }

    if(fuelPrice && fuelPrice > 0){
      const weight = form.getFieldValue("tonn")
      setPrice(weight * fuelPrice)
    }
    else {
      setPrice(0)
    }
  }

  const onAddFuelInOrder = () => {
    if (fuel) {
      let amount = form.getFieldValue("tonn")

      const fuelQuotes: FuelQuoteAddFormValues[] = form.getFieldValue("fuelQuotes") ?? []
      let fuelInOrder = fuelQuotes.find((fq) => fq.id == fuel.id)

      if (fuelInOrder) {
        fuelInOrder.amount = Number(amount)
        fuelInOrder.price = fuelPrice
        fuelInOrder.density = density 
      } 
      else {
        const fuelType = fuelTypes?.find(ft=>ft.id == form.getFieldValue("fuelTypeId")) 

        fuelInOrder = {
          id: fuel.id,
          quoteId: fuel.quoteId,
          amount: amount,
          fuelType: fuelType!.name,
          density: density,
          price: fuelPrice,
          addon: fuel.addon,
          isFuture: fuel.isFuture,
          limitId: fuel.limitId ?? 0
        }

        fuelQuotes.push(fuelInOrder)
      }

      setFullPrice(fuelQuotes.reduce((sum, quote) => sum + (quote.price * quote.amount), 0))

      if (fuel && !fuel.limit) {
        setIsReserve(true)
        form.setFieldValue("isReserve", true)
      }
      else{
        setIsReserve(false)
        form.setFieldValue("isReserve", false)
      }

      refershChosenFuels(fuelQuotes)
      setIsFuelSelect(true)

      form.setFieldValue("fuelQuotes",fuelQuotes)

      setAmountFuel(0)
      setCannotAddFuel(true)
      form.setFieldValue("fuelTypeId", undefined)
      form.setFieldValue("amount",undefined)
      form.setFieldValue("density", undefined)
      form.setFieldValue("price", undefined)
      form.setFieldValue("sellingPrice",undefined)
      form.setFieldValue("tonn",undefined)
      form.setFieldValue("litr",undefined)
      setIsFuelLoaded(false)
      setIsAddFuelProcess(false)
      setPrice(0)
    }
  }

  const refershChosenFuels = (fuelQuotes: FuelQuoteAddFormValues[]) => {
    setChosenFuels(
      fuelQuotes.map((fq) => ({
        id: fq.id,
        quoteId: fq.id,
        amount: fq.amount,
        fuelType: fq.fuelType,
        density: fq.density,
        price: Number(fq.price),
        addon: fq.addon,
        isFuture: fq.isFuture,
        limitId: fq.limitId
      }))
    )
  }

  const onAddFuelType  = () => {
    if (isAddFuelProcess) {
      setIsAddFuelProcess(false)
      setIsFuelSelect(true)
    }
    else{
      setIsAddFuelProcess(true)
      setIsFuelSelect(false)
    }
  }

  const onAddTransportInOrder = async ()  => {
    const driverId = form.getFieldValue("driverId")
    const truckId = form.getFieldValue("truckId")
    const pickupId = form.getFieldValue("pickupId")
    const timeUnloadId = form.getFieldValue("wishTimeSlotId")
    const fuelAmoutTransport = form.getFieldValue("fuelAmoutTransport")

    const orderTransports: TransportAddFormValues[] = form.getFieldValue("transports") ?? []

    if (
      orderTransports.some(
        (ot) =>
          ot.driverId == driverId ||
          ot.truckId == truckId ||
          (pickupId != undefined && ot.pickupId == pickupId)
      )
    ) {
      return
    }

    orderTransports.push({
      driverId: driverId,
      truckId: truckId,
      pickupId: pickupId,
      wishTimeSlot: timeUnloadFuels.find(tuf=>tuf.id == timeUnloadId)?.slot,
      fuelAmount: fuelAmoutTransport
    })

    setIsTransportSelect(true)
    refreshChosenTransports(orderTransports)
    form.setFieldValue("transports", orderTransports)
    form.setFieldValue("wishTimeSlot", timeUnloadFuels.find(f=>f.id == timeUnloadId)?.slot)
    form.setFieldValue("wishTimeSlotId",undefined)
    form.setFieldValue("driverId", undefined)
    form.setFieldValue("truckId", undefined)
    form.setFieldValue("pickupId", undefined)
    form.setFieldValue("fuelAmoutTransport", undefined)
    
    setIsDriverChosen(false)
    setIsTruckChosen(false)
  }

  const setFuelSellingPrice = (value: number | null) =>{
    if (value && value > 0) {
        setFuelPrice(value) 
        calculateFinalPrice(value,density)

    }
    else {
        setFuelPrice(0)
        calculateFinalPrice(0,0)
    }
  }

  const onCreateOrder = ()=>{
    form.submit()
  }
  const onFinish = async (data: OrderAddFormValues) => {
    const fuelQuotes: FuelQuoteAddFormValues[] = form.getFieldValue("fuelQuotes") ?? []
    const orderTransports: TransportAddFormValues[] = form.getFieldValue("transports") ?? []
    const isFuture: boolean = fuelQuotes.some((fq) => fq.isFuture == true)

    const wishTimeSlot = form.getFieldValue("wishTimeSlot")
      if (wishTimeSlot) {
       data.wishPeriodTime = wishTimeSlot
      }
      else {
        data.wishPeriodTime = timeUnloadFuels.find(t=>t.id == form.getFieldValue("wishTimeSlotId"))?.slot
      }
    data.deliveryType = DeliveryType.Pickup  
    data.fuelQuotes = fuelQuotes
    data.transports = orderTransports
    data.isFuture = isFuture
    data.isReserve = isReserve

    await tryOrder(data)
  }

  const tryOrder = async (data: OrderAddFormValues | undefined) => {
    try {
      if (data == undefined) return

      const request: IOrderCreate = {
        //Преобразуем дату из формы, чтобы игнорировать таймзону
        date: dayjs(data.date).format("YYYY-MM-DD"),
        deliveryType: DeliveryType.Pickup,
        isBackdating: true,
        backdatingStatus: backdatingStatus,
        delivery: {
          deliveryAddressId: data.deliveryAddressId ?? 0,
          pump: data.pump,
          commentary: data.deliveryCommentary
        },
        depotId: data.depotId,
        exchangeType: data.exchangeType,
        customerId: data.customerId,
        deliveryPrice: data?.deliveryCost ?? 0,
        wishTimeSlot: data.wishPeriodTime,
        isReserve: false,
        transports: data.transports.map((t: TransportAddFormValues) => ({
          driverId: t.driverId,
          truckId: t.truckId,
          pickupId: t.pickupId,
          wishTimeSlot: t.wishTimeSlot,
          fuelAmount: t.fuelAmount
        })),
        fuelBackdatingQuoteOrder: data.fuelQuotes.map(fq=>({
          id: fq.id,
          date: orderDate!,
          sellingPrice: fq.price,
          density:fq.density,
          amount: fq.amount,
          fuelId: fq.id
        })),
        fuelQuoteOrders: data.isFuture
          ? []
          : data.fuelQuotes.map((fq: FuelQuoteAddFormValues) => ({
            id: fq.quoteId,
            amount: Number(fq.amount),
            limitId: fq.limitId
          })),
        fuelFutureOrders: data.isFuture
          ? data.fuelQuotes.map((fq: FuelQuoteAddFormValues) => ({
            id: fq.id,
            amount: Number(fq.amount),
            avgPrice: fq.price,
            addon: fq.addon,
            limitId: fq.limitId == 0 ? null : fq.limitId
          }))
          : [],
          newAddresses: [],
          newDrivers: [],
          newTransports: []
      }
      await createMutation.mutateAsync(request)

    } catch {
    } finally {
      onCloseHandler()
    }
  }   

    return (
        <>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row  gutter={16}>
            <Col >
              <Text type="secondary">Статус заказа:</Text>
            </Col>
            <Col>
              <Segmented options={orderBackDatingStatus} value={backdatingStatus} onChange={(e)=>setBackdatingStatus(e)} /> 
            </Col>
          </Row>
        <Row>
            <Col>
              <Form.Item
              hidden={ true }
              name="isReserve"
              valuePropName="checked"/>
            </Col>
          </Row>
           <Row  gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                    name="customerId"
                    label="Клиент"
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста выберите клиента"
                      }
                    ]}
                >
                  <Select
                      placeholder="Клиент"
                      onChange={onCustomerChange}
                      options={customersData?.response?.data.map((cust) => ({
                        value: cust.id,
                        label: cust.name
                      }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item 
                  name={"depotId"}
                  label= "Нефтебаза"
                  rules={[
                    {
                      required : true,
                      message : "Пожалуйста выберите нефтебазу"
                    }
                  ]}>
                    <Select 
                      disabled= {isFuelSelect || isAddFuelProcess}
                      placeholder = "Нефтебаза"
                      onChange={(value, options) => { onDepotChange(value, options)}}
                    options={depots?.response.map((depot) => ({
                      value: depot.id,
                      label: depot.name
                    }))}>
                    </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                    name="date"
                    label="Дата"
                    rules={[
                      { required: true, message: "Пожалуйста выберите дату" }
                    ]}
                >
                  <DatePicker
                      disabled= {isFuelSelect || isAddFuelProcess}
                      style={{ width: "100%" }}
                      placeholder="Дата заказа"
                      onChange={onDateChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} >
              <Col xs={24} md={12}>
                <Form.Item
                  name="fuelTypeId"
                  label="Тип топлива"
                  >
                    <Select
                      disabled= {isFuelSelect && !isAddFuelProcess}
                      onChange={fuelTypeChange}
                      placeholder="Тип топлива"
                      options={fuelTypes?.map((fuelType) => ({
                        value: fuelType.id,
                        label: `${fuelType.name}`
                    }))}
                    />
                    </Form.Item>
                </Col>
              <Col xs={24} md={12}>
                <Form.Item
                    name="exchangeType"
                    label="Вид обменной доверенности"
                    rules={[
                      {
                        required: false,
                        message: "Пожалуйста выберите Вид обменной доверенности"
                      }
                    ]}
                >
                  <Select
                      placeholder="Вид обменной доверенности"
                      showSearch
                      options={exchangeTypes.map((type) => ({
                        value: type.type,
                        label: type.name
                      }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            {((chosenFuels && chosenFuels.length > 0) || isFuelLoaded) ? (
              <>
              <Row gutter={[16, 16]} hidden={isFuelSelect && !isAddFuelProcess}>
                <Form.Item
                  name="sellingPrice"
                  label="Цена реализации"> 
                    <InputNumber
                    disabled={isFuelSelect && !isAddFuelProcess}
                    addonAfter="₽"
                    placeholder="Цена реализации"
                    min={0}
                    step={0.01}
                    onChange={setFuelSellingPrice}
                    />
                </Form.Item>
              </Row>
              <Row gutter={[16, 16]} hidden={isFuelSelect && !isAddFuelProcess}>
                <Col xs={12} md={6}>
                <Form.Item label="Объем, л." name="litr">
                    <InputNumber
                      className={classes["inputNumber"]}
                      onChange={() => onWeightOrVolumeChange()}
                      type="number"
                      placeholder="Объем"
                      min={1}
                      />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Item label="Масса, т." name="tonn">
                    <InputNumber
                      className={classes["inputNumber"]}
                      onChange={() => onWeightOrVolumeChange()}
                      type="number"
                      placeholder="Масса"
                      min={1}
                      />
                  </Form.Item>
                </Col>
                <Col xs={12} md={6} style={{ display: "flex", alignItems: "flex-end" }}>
                  <Button
                    hidden={ !isFuelLoaded}
                    disabled={price === 0 }
                    className={classes["align-center"]}
                    type="primary"
                    size="large"
                    shape="round"
                    onClick={onAddFuelInOrder}>
                      Добавить{price > 0 ? ` (${getMoney(price)})` : ""}
                    </Button>
                </Col>
              </Row>
              <Row className={classes["density"]}>
                {densityText}
              </Row>
              <div
                  hidden={!chosenFuels || chosenFuels.length == 0}
                  className={classes["order-row"]}
              >
                <OrderFuelTable
                    additionalColumns={additionalFuelColumns}
                    fuels={chosenFuels}
                />
              </div>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} className={classes["col-flex"]}>
                  <Text strong>Итого: {getMoney(fullPrice)} </Text>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12} className={classes["col-flex"]}>
                    <Button
                       hidden={!chosenFuels || chosenFuels.length == 0}
                        className={classes["add-fuel-btn"]}
                        type="primary"
                        size="large"
                        shape="round"
                        onClick={onAddFuelType}
                    >
                      {isAddFuelProcess ? "отменить добавление" : "добавить топливо"}
                    </Button>
                </Col>
              </Row>
            </>
            ) : <Alert message="Выберите нефтебазу, тип топлива и дату" type="warning" showIcon closable />}
            <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item  name="driverId" label="Водитель" >
                    <Select
                        disabled = {isTransportSelect}
                        onChange={(e: number) =>
                            setIsDriverChosen(e != undefined)
                        }
                        placeholder="Водитель"   
                        options={allDrivers
                            .filter(
                                (d) =>
                                    !chosenTransports.some(
                                        (ct) => ct.driverId == d.id
                                    )
                            )
                            .map((driver) => ({
                              value: driver.id,
                              label: driver.fullName
                            }))}
                        dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                Нет водителя в списке? Добавьте в справочнике водителей!
                              </Space>
                            </>
                        )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item  name="truckId" label="Транспорт" >
                    <Select
                    disabled = {isTransportSelect}
                        onChange={(e: number) => setIsTruckChosen(e != undefined) }
                        placeholder="Транспорт"
                        options={allTrucks
                            .filter(
                                (tr) =>
                                    !chosenTransports.some(
                                        (ct) => ct.truckId == tr.id
                                    )
                            )
                            .map((transport) => ({
                              value: transport.id,
                              label: formatTransport(transport)
                            }))}
                        dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                Нет транспорта в списке? Добавьте в справочнике транспорта!
                              </Space>
                            </>
                        )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col xs={24} md={12}>
                  <Form.Item name="pickupId" label="Прицеп">
                    <Select
                        disabled = {isTransportSelect}
                        placeholder="Прицеп"
                        showSearch
                        options={allPickups
                            .filter(
                                (p) =>
                                    !chosenTransports.some(
                                        (ct) => ct.pickupId == p.id
                                    )
                            )
                            .map((transport) => ({
                              value: transport.id,
                              label: formatTransport(transport)
                            }))}
                        dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                Нет прицепа в списке? Добавьте в справочнике транспорта!
                              </Space>
                            </>
                        )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col
                    xs={24}
                    md={12}
                    className={classes["col-flex"]}
                >
                  <Button
                      disabled={ (chosenTransports.length == 1 && chosenFuels.length > 0) ||!isDriverChosen || !isTruckChosen }
                      className={classes["align-center"]}
                      type="primary"
                      size="large"
                      shape="round"
                      onClick={onAddTransportInOrder}
                  >
                    Добавить
                  </Button>
                </Col>
              </Row>

              <div
                  hidden={
                      chosenTransports == undefined ||
                      chosenTransports.length == 0
                  }
                  className={classes["order-row"]}
              >
                <TransportTable
                    orderDate={orderDate}
                    additionalColumns={additionalTransportColumns}
                    transports={chosenTransports}
                    canApprove={false}
                />
              </div>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Button
                    style={{ marginTop : "12px" }}
                    disabled={ chosenFuels.length == 0 }
                    type="primary"
                    size="large"
                    shape="round"
                    onClick={onCreateOrder} >
                  Создать
                </Button>
              </Col>
            </Row>
            <SimpleModal ref={simpleModalComponent}></SimpleModal>
        </Form>  
        </>
    )
}
