import { useMutation, useQueryClient } from "@tanstack/react-query"

import { FUEL_QUOTES_QUERY_KEY } from "@/constants/query"
import { createFuelQuote, deleteFuelQuotes, editFuelQuoteSettingsCategory, updateFuelQuote } from "@/http/fuelQuote"

import { useMessage } from "@/hook/useMessage"

export const useFuelQuotesMutation = () => {
  const client = useQueryClient()
  const { Success, Error, Process } = useMessage(FUEL_QUOTES_QUERY_KEY)

  const onSuccess = () => {
    client.invalidateQueries([FUEL_QUOTES_QUERY_KEY]).then()
    Success()
  }

  const onMutate = () => {
    Process()
  }

  const onError = (e: any) => {
    Error(e)
  }

  const createMutation = useMutation({
    mutationFn: createFuelQuote,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const updateMutation = useMutation({
    mutationFn: updateFuelQuote,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const deleteMutation = useMutation({
    mutationFn: deleteFuelQuotes,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  const editFuelQuoteSettingsMutation = useMutation({
    mutationFn: editFuelQuoteSettingsCategory,
    onSuccess: () => onSuccess(),
    onMutate: () => onMutate(),
    onError: (e) => onError(e)
  })

  return {
    createMutation,
    updateMutation,
    deleteMutation,
    editFuelQuoteSettingsMutation
  }
}
