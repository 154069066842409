import { client } from "@/http/client"

import { IResponse } from "@/types/api"
import { ICreateTimeUnloadFuel, ITimeUnloadFuel } from "@/types/ITimeUnloadFuel"

export interface IAvailableTimeUnloadFuelParam {
    date: Date
}
export interface ICheckOnFreeSlotParam{
    date: Date
    slot: string
}

export const getAvailableTimeUnloadFuel = (params: IAvailableTimeUnloadFuelParam) 
: Promise<IResponse<ITimeUnloadFuel[]>> => 
    client.get("/TimeUnloadFuel/Available", { params })

export const getFreeSlotForUnload =(params:ICheckOnFreeSlotParam) 
: Promise<IResponse<ITimeUnloadFuel>> => 
    client.get("/TimeUnloadFuel/Free", { params })

export const createTimeUnload =(body:ICreateTimeUnloadFuel) 
: Promise<IResponse<ITimeUnloadFuel>> => 
    client.post("/TimeUnloadFuel", body)
